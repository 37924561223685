import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSliders } from '@fortawesome/free-solid-svg-icons';

const SlotsFilter = ({ filter, setFilter, filterOptions }) => {
    return (
        <div className="selectContainer">
            <FontAwesomeIcon icon={faSliders} style={{ marginRight: '5px', paddingLeft: '15px', color: '#fff', fontSize: '20px' }} />
            <select className='filterSelect' value={filter} onChange={(e) => setFilter(e.target.value)}>
                {filterOptions.map(option => (
                    <option  className='filterDropdown' key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default SlotsFilter;

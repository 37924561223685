import React from 'react'

export default function PageNotFound() {
  return (
    <>
        <main className='pageNotFound'>
           <div>
                <img src="/images/SG8-logo.png" alt="" />
                <h1>The page you are looking for may have been moved, deleted or possibly never existed.</h1>
           </div>
        </main>
    </>
  )
}
